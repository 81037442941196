import React from "react";
import { Link } from "react-router-dom";

import "./mailTo.css";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <div className="email">
        <Link className="email_button"
            to='#'
            onClick={(e) => {
                window.location = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
        </div>
    );
};

export default ButtonMailto;
import React from "react";

import ButtonMailto from "../components/mailTo";

import "./index.css";

const Index = () => {
  return (
    <>
      <div className="section_one">
        <div className="section_one_neo_logo">
          NEONATII
        </div>
        <h1>Consultancy Services and</h1>
        <h1>Transport Management Applications</h1>
      </div>
      <div className="section_two">
        <h1>Services</h1>
        <h2>Consultancy services within areas of</h2>
        <div className="row">
          <div className="column">
            <li>Supply and Value Chain Operations</li>
              <p>Design and structure</p>
            <li>Forecasting</li>
              <p>Demand Planning</p>
            <li>Warehouse Logistics</li>
              <p>Procurement and Refill</p>
              <p>Stock Balancing</p>
              <p>Staging Area operation</p>
          </div>
          <div className="column">
            <li>Transport Management Systems</li>
              <p>Extensive experience in Transport Distribution</p>
              <p>Strategic / Tactical Assesment</p>
              <p>Operational Planning</p>
              <p>Execution</p>
              <p>Analysis and Real Time Visibility</p>
            <li>Implementation of IT-System</li>
          </div>
        </div>
      </div>
      <div className="section_three">
        <h1>Application</h1>
        <h2>Ongoing development project within the Transport Management Area</h2>
        <h3>Schedule Release late 2022</h3>
        <img src="./assets/neonatii_tos_v.png" width="100%" alt="NEONATII TOS V"/>
      </div>
      <div className="section_four">
        <h1>About Us</h1>
        <li>Experienced Consultancy - more than 30 years within logistics operation</li>
        <li>Developed several applications covering</li>
          <p>- Demand Forecasting</p>
          <p>- Procurement Planning</p>
          <p>- Warehouse Optimalization</p>
          <p>- Transport Management System</p>
        <li>Solution Architect and Design</li>
        <div className="established">Company is re-established and based in Norway</div>
      </div>
      <div className="section_five">
        <h1>Contact</h1>
        <ButtonMailto label="Write us an E-Mail" mailto="mailto:post@neonatii.com" />
        <h2>or give us a ring on +47 900 86 568</h2>
      </div>

    </>
  );
};

export default Index;

